@font-face {
    font-family: "SF Collegiate Solid";
    src: url("../images/fonts/SF-Collegiate-Solid.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

.user_mode {
    .site-background {
        top: 0 !important;
        background-size: cover;
        background-position: center center;
        @media screen and (max-width: $breakpoint-sm-max) {
            background: url('../images/mobileBackground.jpg');
            top: 60px !important;
            background-size: 100%;
            background-position: center top;
        }
    }
    #topNav,
    #topNavPlaceholder,
    .snFooterContainer {
        display: none;
    }
    #siteContainer {
        background: transparent;
        @media screen and (max-width: $breakpoint-sm-max) {
            padding: 0;
        }
        #panelTwo:before {
            background-color: transparent;
            box-shadow: none;
        }
        .layoutContainer {
            background-color: transparent;
            padding-bottom: 10px;
            @media screen and (max-width: $breakpoint-sm-max) {
                padding: 20px 0;
            }
            .text-logo {
                .heroPhotoElement {
                    img {
                        max-width: 1000px;
                        @media screen and (max-width: $breakpoint-sm-max) {
                            padding: 0 15px;
                        }
                    }
                }
            }
            .custom-text {
                .textBlockElement {
                    @media screen and (max-width: $breakpoint-sm-max) {
                        margin-top: 0;
                        margin-bottom: 0;
                    }
                    h3 {
                        font-family: 'SF Collegiate Solid';
                        font-size: 46px;
                        color: #fff;
                        font-weight: normal;
                        @media screen and (max-width: $breakpoint-sm-max) {
                            font-size: 24px;
                        }
                    }
                }
            }
        }
        .logo-container {
            .column-1 {
                @media screen and (max-width: $breakpoint-sm-max) {
                    padding-bottom: 40px;
                }
            }
            .custom-logo {
                .heroPhotoElement {
                    a {
                        img {
                            max-width: 300px;
                            @media screen and (max-width: $breakpoint-sm-max) {
                                max-width: 185px;
                            }
                        }
                    }
                }
            }
            .custom-button {
                h4 {
                    a {
                        background-color: #158646;
                        text-align: center;
                        justify-content: center;
                        border-radius: 5px;
                        max-width: 270px;
                        margin: auto;
                        padding: 17px;
                        font-family: 'Oswald', sans-serif;
                        font-size: 24px;
                        font-weight: normal;
                        transition: background-color $transition-standard;
                        @media screen and (max-width: $breakpoint-sm-max) {
                            max-width: 234px;
                        }
                        &:hover {
                            background-color: darken(#158646, 5%);
                        }
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}